import React, {Component} from 'react';
import {Button, Form} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class ErrorReportFilteringForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            clusterID: this.props.clusterID,
            hourWritten: this.props.hourWritten,
            webApp: this.props.webApp,
            logMessage: this.props.logMessage,
            startDate: new Date(),
            endDate: new Date()
        };
    }

    handleClusterIDChange(newID) {
        this.setState({
            clusterID: newID.target.value
        });
    }

    handleHourWrittenChange(newHour) {
        this.setState({
            hourWritten: newHour.target.value
        });
    }

    handleWebAppChange(newApp) {
        this.setState({
            webApp: newApp.target.value
        });
    }

    handleLogMessageChange(newMsg) {
        this.setState({
            logMessage: newMsg.target.value
        });
    }

    handleStartChange(date) {
        this.setState({
            startDate: date
        });
    }

    handleEndChange(date) {
        this.setState({
            endDate: date
        });
    }

    onClickApply(id, hour, start, end, app, msg) {
        this.props.loadFilteredData(id, hour, start, end, app, msg);
        this.props.onCloseModal();
    }

    render() {
        return (
            <Form>
                <div>{"Enter the filters you'd like to apply to the table below. Each field is optional "}</div>
                <div>{"except time range."}</div>
                <br/>
                <Form.Field>
                    <label htmlFor="clusterID">Cluster ID</label>
                    <input type="text" placeholder="Cluster ID" id="clusterID" onChange={evt => this.handleClusterIDChange(evt)} value={this.state.clusterID} style={{fontFamily: "Turner, helvetica, arial, sans-sarif"}}/>
                </Form.Field>
                <Form.Field>
                    <label htmlFor="hourWritten">Hour Written to BigQuery</label>
                    <input type="text" id="hourWritten" placeholder="Hour Written to BigQuery" onChange={evt => this.handleHourWrittenChange(evt)} value={this.state.hourWritten} style={{fontFamily: "Turner, helvetica, arial, sans-sarif"}} />
                </Form.Field>
                <Form.Field>
                    <label htmlFor="timeRange">Time Range</label>
                    <DatePicker
                        selected={this.state.startDate}
                        value={this.state.startDate}
                        onChange={evt => this.handleStartChange(evt)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="yyyy-MM-dd HH:mm:00"
                        timeCaption="Time"
                    />
                    <DatePicker
                        selected={this.state.endDate}
                        value={this.state.endDate}
                        onChange={evt => this.handleEndChange(evt)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="yyyy-MM-dd HH:mm:00"
                        timeCaption="Time"
                    />
                </Form.Field>
                <Form.Field>
                    <label htmlFor="webApp">Web App</label>
                    <input type="text" id="webApp" placeholder="Web App" onChange={evt => this.handleWebAppChange(evt)} value={this.state.webApp} style={{fontFamily: "Turner, helvetica, arial, sans-sarif"}} />
                </Form.Field>
                <Form.Field>
                    <label htmlFor="logMessage">Log Message</label>
                    <div>Enter a key phrase or the exact message.</div>
                    <input type="text" id="logMessage" placeholder="Log Message" onChange={evt => this.handleLogMessageChange(evt)} value={this.state.logMessage} style={{fontFamily: "Turner, helvetica, arial, sans-sarif"}} />
                </Form.Field>

                <Button onClick={() => this.onClickApply(
                    this.state.clusterID.toString(),
                    this.state.hourWritten.toString(),
                    this.state.startDate.toString(),
                    this.state.endDate.toString(),
                    this.state.webApp.toString(),
                    this.state.logMessage.toString()
                )}>Apply</Button>
            </Form>
        );
    }
}

export default ErrorReportFilteringForm;