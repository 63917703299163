import React from "react";
import {Button, Form, Icon, Modal, Input, Label, Dropdown} from "semantic-ui-react";
import 'jsoneditor/dist/jsoneditor.css'
import DeploymentDataProvider from "../../../Services/DeploymentDataProvider";
import {v4 as uuidv4} from 'uuid';


class DeploymentDetailPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            crate: this.props.crate,
            forklifters: this.props.forklifters  || [],
            tag: "",
            githubAccessToken: "",
            confirmationText: "",
            deploymentTargets:[
                {
                    key: 'gaes',
                    text: 'Google App Engine',
                    value: 'gaes'
                },
                {
                    key: 'lambda',
                    text: 'AWS Lambda',
                    value: 'lambda'
                },
                {
                    key: 'fargate',
                    text: 'Fargate',
                    value: 'fargate'
                }
            ],
            deploymentTarget: "" ,
            enviormentList: [
                {
                    key: 'dev',
                    text: 'dev',
                    value: 'dev'
                },
                {
                    key: 'ref',
                    text: 'ref',
                    value: 'ref'
                },
                {
                    key: 'ite',
                    text: 'ite',
                    value: 'ite'
                },
                {
                    key: 'prod',
                    text: 'prod',
                    value: 'prod'
                },
            ]
        };
        DeploymentDataProvider.init({baseURL: process.env.SYNAPSE_CORE_URL})
    }


    updateCrate = () => {
        const userId = this.props.user.sub || null;
        const crateId = this.state.crate._id;
        const name = (this.state.crate.name);
        const env = (this.state.crate.env);
        const accessKey = (this.state.crate.accessKey);
        const secretKey = (this.state.crate.secretKey);
        const deploymentAccessKey = (this.state.crate.deploymentAccessKey);
        const deploymentSecretKey = (this.state.crate.deploymentSecretKey);
        const aeDeploymentKey = (this.state.crate.aeDeploymentKey);
        const githubRepoName = (this.state.crate.githubRepoName);

        let requestCrateId = crateId ? crateId : uuidv4();
        DeploymentDataProvider.saveCrate(userId, {
            "_id": requestCrateId,
            "name": name,
            "env": env,
            "accessKey": accessKey,
            "secretKey": secretKey,
            "deploymentAccessKey": deploymentAccessKey,
            "deploymentSecretKey": deploymentSecretKey,
            "aeDeploymentKey": aeDeploymentKey,
            "githubRepoName": githubRepoName
        }).then(resp => {
            resp.json()
                .then(payload => {
                    if (payload.message) {
                        this.props.toast('Message', payload.message, 'success');

                    } else if (payload.error) {
                        this.props.toast('Message', payload.error, 'error');
                    }

                    this.props.onUpdateCrate();
                });
        });
    };
    //
    deleteCrate = () => {
        const userId = this.props.user.sub || null;
        const crateId = this.state.crate._id;

        if (!crateId) {
            this.props.toast('Message', "You cannot delete a crate that does not exist", 'error');
            return;
        }

        var confirmation = confirm("Are you should you want to delete this crate?");
        if (confirmation !== true) {
            return;
        }

        DeploymentDataProvider.deleteCrate(userId, crateId).then(resp => {
            resp.json()
                .then(payload => {
                    if (payload.message) {
                        this.props.toast('Message', payload.message, 'success');
                    } else if (payload.error) {
                        this.props.toast('Message', payload.error, 'error');
                    }
                    this.props.onUpdateCrate()
                });
        });
    };

    deployCrate = () => {
        const tag = this.state.tag;
        const deploymentTarget = this.state.deploymentTarget;
        const githubRepoName =  this.state.crate.githubRepoName;
        const githubAccessToken = this.state.githubAccessToken;
        const name = this.state.crate.name;
        const env = this.state.crate.env;
        const requiredName = env +"/"+ name;
        const accessKey = this.state.crate.accessKey;
        const secretKey = this.state.crate.secretKey;
        const deploymentAccessKey  = this.state.crate.deploymentAccessKey;
        const deploymentSecretKey = this.state.crate.deploymentSecretKey;
        const appengineDeploymentKey = this.state.crate.aeDeploymentKey;

        if (!tag) {
            this.props.toast('Message', "You must provide a tag.", 'error');
            return;
        }

        if (!deploymentTarget) {
            this.props.toast('Message', "You must select a target.", 'error');
            return;
        }

        if (this.state.confirmationText !== requiredName) {
            this.props.toast('Message', "Please be sure to type the confirmation text exactly as requested.", 'error');
            return;
        }

        DeploymentDataProvider.getTags(githubRepoName, githubAccessToken).then(resp => {
            resp.json()
                .then(payload => {
                    if (Array.isArray(payload)) {
                        let isTagValid = false;
                        payload.forEach(tagResponse => {
                            if (tagResponse.name === tag) {
                                isTagValid = true;
                            }
                        });

                        if (isTagValid) {
                            this.props.toast('Message', "Tag Exists, deploying now", 'success');
                            window.open(
                                'https://github.com/turnercode/'+githubRepoName+'/actions');
                            DeploymentDataProvider.deployApp(githubRepoName,githubAccessToken,
                                name, env, tag, accessKey, secretKey, deploymentAccessKey, deploymentSecretKey, appengineDeploymentKey, deploymentTarget)
                        } else {
                            this.props.toast('Message', "Tag does not exist, please tag a release in Github first", 'error');
                        }
                    } else  {
                        this.props.toast('Message', "Call to github failed, please try checking your authtoken", 'error');
                    }
                    this.props.onUpdateCrate();
                });
        });
    };


    handleChange = id => event => {
        let text = event.target.value;
        let crate = this.state.crate;
        crate[id] = text;
        this.setState({
            crate: crate
        })
    };

    handleSelection = id => (e, {value}) => {
        let crate = this.state.crate;
        crate[id] = value;
        this.setState({
            crate: crate
        })
    };

    handleAddition = (e, {value}) => {
        this.setState((prevState) => ({
            enviormentList: [{text: value, value}, ...prevState.enviormentList],
        }))
    };


    handleModalChange = id => event => {
        let text = event.target.value;
        this.setState({
            [id]: text
        })
    };

    handleModalSelection = id => (e, {value}) =>{
        let text = value;
        this.setState({
            [id]: text
        });
    };


    render() {
        let crateId = "";
        let name = "";
        let env = "";
        let accessKey = "";
        let secretKey = "";
        let deploymentAccessKey = "";
        let deploymentSecretKey = "";
        let aeDeploymentKey = "";
        let githubRepoName = "";
        this.state.crate = this.props.crate;
        let crate = this.state.crate;
        let forklifters = this.props.forklifters || [];
        if (crate && !(Object.keys(crate).length === 0 && crate.constructor === Object)) {
            crateId = crate._id;
            name = crate.name;
            env = crate.env;
            accessKey = crate.accessKey;
            secretKey = crate.secretKey;
            deploymentAccessKey = crate.deploymentAccessKey;
            deploymentSecretKey = crate.deploymentSecretKey;
            aeDeploymentKey = crate.aeDeploymentKey;
            githubRepoName = crate.githubRepoName;

            let addNewEnv = true;
            this.state.enviormentList.forEach(environment => {
                if (environment.key === env) {
                    addNewEnv = false;
                }
            });

            if (addNewEnv) {
                this.state.enviormentList.push({
                    key: env,
                    text: env,
                    value: env
                })
            }
        }

        return (
            <Form>
                <Form.Field>
                    <label>Crate Management</label>
                </Form.Field>
                <Form.Field>
                    <label>Crate ID</label>
                    <input
                        value={crateId}
                        disabled={true}
                        type="text"
                        onChange={this.handleChange("_id")}
                        id="crateIdInput"/>
                </Form.Field>
                <Form.Field>
                    <label>Name</label>
                    <input
                        value={name}
                        type="text"
                        onChange={this.handleChange("name")}
                        id="nameInput"/>
                </Form.Field>
                <Form.Field>
                    <label>Environment</label>
                    <Dropdown
                        fluid
                        allowAdditions
                        search
                        selection
                        options={this.state.enviormentList}
                        value={env}
                        onChange={this.handleSelection("env")}
                        onAddItem={this.handleAddition}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Github Repo</label>
                    <input
                        value={githubRepoName}
                        type="text"
                        placeholder="Ex: ngtv-example"
                        onChange={this.handleChange("githubRepoName")}
                        id="githubRepoNameInput"/>
                </Form.Field>
                <Form.Field>
                    <label>Environment Access Key</label>
                    <Dropdown
                        fluid
                        allowAdditions
                        search
                        selection
                        options={forklifters}
                        value={accessKey}
                        onChange={this.handleSelection("accessKey")}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Environment Secret Key</label>
                    <Dropdown
                        fluid
                        allowAdditions
                        search
                        selection
                        options={forklifters}
                        value={secretKey}
                        onChange={this.handleSelection("secretKey")}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Deployment Access Key</label>
                    <Dropdown
                        fluid
                        allowAdditions
                        search
                        selection
                        options={forklifters}
                        value={deploymentAccessKey}
                        onChange={this.handleSelection("deploymentAccessKey")}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Deployment Secret Key</label>
                    <Dropdown
                        fluid
                        allowAdditions
                        search
                        selection
                        options={forklifters}
                        value={deploymentSecretKey}
                        onChange={this.handleSelection("deploymentSecretKey")}
                    />
                </Form.Field>
                <Form.Field>
                    <label>App Engine Deployment Key</label>
                    <Dropdown
                        fluid
                        allowAdditions
                        search
                        selection
                        options={forklifters}
                        value={aeDeploymentKey}
                        onChange={this.handleSelection("aeDeploymentKey")}
                    />
                </Form.Field>
                <br/>
                <br/>
                <Button color={"blue"} onClick={this.updateCrate}> Save </Button>
                <Button onClick={this.deleteCrate}> Delete </Button>

                <Modal trigger={<Button primary floated={"right"} labelPosition={"right"}>
                    <Button basic color='blue'>
                        <Icon name='truck'/>
                        Ship It!
                    </Button>
                </Button>}>
                    <Modal.Header>Confirm Deployment</Modal.Header>
                    <Modal.Content>
                        <Input
                            icon='tag'
                            iconPosition='left'
                            label={{tag: true, content: 'Add Tag'}}
                            labelPosition='right'
                            onChange={this.handleModalChange("tag")}
                            placeholder='Enter tag'
                        />
                    </Modal.Content>
                    <Modal.Content>
                        <Input
                            label={{basic: false, content: 'Github Access', color: 'gray'}}
                            labelPosition='left'
                            onChange={this.handleModalChange("githubAccessToken")}
                            placeholder='Token'
                        />
                    </Modal.Content>
                    <Modal.Content>
                        <Label>Please Type The Name Of The Environment and Name of Deployment( {env +"/"+ name} ).</Label>
                    </Modal.Content>
                    <Modal.Content>
                        <Input
                            onChange={this.handleModalChange("confirmationText")}
                            placeholder={env +"/"+ name}
                    />
                    </Modal.Content>
                    <Modal.Content>
                        <Form.Field>
                            <label>Target Deployment</label>
                            <Dropdown
                                fluid
                                search
                                selection
                                options={this.state.deploymentTargets}
                                onChange={this.handleModalSelection("deploymentTarget")}
                            />
                        </Form.Field>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button primary onClick={this.deployCrate}>
                            Deploy <Icon name="play right"/>
                        </Button>
                    </Modal.Actions>
                </Modal>

                <br/>
            </Form>
        )
    }
}

export default DeploymentDetailPage
