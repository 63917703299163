import React from 'react';
import ReactTable from "react-table-v6";
import {Header, Grid, Button, Icon} from 'semantic-ui-react'
import DeploymentDetailPage from "./DeploymentDetailPage";
import DeploymentDataProvider from "../../../Services/DeploymentDataProvider";


class DeploymentMasterDetailView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedKey: "",
            selectedCrate: {},
            loading: true,
            _id: "",
            name: "",
            env:"",
            crates: [],
            forklifters: [],
            githubRepoName:"",
            accessKey: "",
            secretKey: "",
            deploymentAccessKey: "",
            deploymentSecretKey: "",
            aeDeploymentKey: ""
        };

        DeploymentDataProvider.init({baseURL: process.env.SYNAPSE_CORE_URL});

        this.getAllCrates();
        this.retrieveForklift();
    }

    getAllCrates = () => {
        DeploymentDataProvider.getCrates()
            .then(payload => {
                payload.json()
                    .then(crates => {
                        let transformedCrates = crates.map(crate => {

                            const transformedCrate = {
                                "_id": crate._id,
                                "name": crate.name,
                                "env": crate.env,
                                "accessKey": crate.accessKey,
                                "secretKey": crate.secretKey,
                                "githubRepoName": crate.githubRepoName,
                                "deploymentAccessKey": crate.deploymentAccessKey,
                                "deploymentSecretKey": crate.deploymentSecretKey,
                                "aeDeploymentKey": crate.aeDeploymentKey
                            };
                            return transformedCrate;
                        });
                        this.setState({
                            crates: transformedCrates,
                            loading: false
                        });
                    });
            });
    };

    retrieveForklift = () => {
        DeploymentDataProvider.getForklift()
            .then(payload => {
                payload.json()
                    .then(forklifters => {
                        let retrievedForklifters = [];
                        forklifters.sort();
                        console.log(forklifters);

                        forklifters.forEach(forklift => {
                            retrievedForklifters.push({
                                value: forklift.value,
                                key: forklift.value,
                                text: forklift._id
                            });
                        });
                        console.log(retrievedForklifters);
                        this.setState({
                            forklifters: retrievedForklifters
                        });
                    });
            });
    };

    handleSelectCrate = crate => {

        this.setState({
            selectedCrate: crate
        }, () => {
        });
    };

    handleChange = id => event => {
        let text = event.target.value;
        this.setState({
            [id]: text
        })
    };

    newCrateClicked = (e, { value }) => {
        this.setState({
            selectedCrate: {},
        }, () => {
        });
    };

    copyCrateClicked = (e, { value }) => {
        const copiedCrate =  this.state.selectedCrate;
        copiedCrate._id = "";
        this.setState({
            selectedCrate: copiedCrate,
        }, () => {
        });
    };



    render() {
        let that = this;
        return(
            <Grid style={{height: '100vh'}}>
                <Grid.Row>
                    <Grid.Column width={10} className='masterContainer'>
                        <ReactTable
                            columns={[
                                { Header: 'Crate ID', accessor: '_id' },
                                { Header: 'Crate Name', accessor: 'name' },
                                { Header: 'Environment', accessor: 'env' },
                                { Header: 'Github Repo', accessor: 'githubRepoName' }

                            ]}
                            data={this.state.crates}
                            loading={this.state.loading}
                            filterable
                            defaultSorted={[
                                {
                                    id: "_id",
                                    desc: false
                                }
                            ]}
                            defaultFilterMethod={(filter, rows) => rows[filter.id].toString().toLowerCase().includes(filter.value.toString().toLowerCase())}
                            className="-striped -highlight"
                            getTrProps={(state, rowInfo, column, instance) => {
                                return {
                                    onClick(event, handleOriginal) {
                                        that.setState({selectedKey: rowInfo.row._id });
                                        that.handleSelectCrate(rowInfo.original)
                                    },
                                    style: {
                                        background: rowInfo && rowInfo.row && that.state.selectedKey === rowInfo.row._id ? 'rgba(65, 83, 175, .5)' : '',
                                    }
                                }
                            }}
                            style={{height: '75vh'}}
                        />
                        <Button color={"blue"} onClick={this.newCrateClicked}> Add New Crate </Button>
                        <Button onClick={this.copyCrateClicked}>
                            <Icon name='fork' />
                            Fork Crate
                        </Button>

                    </Grid.Column>
                    <Grid.Column width={6} className='detailsContainer'>
                        <DeploymentDetailPage
                            toast={this.props.toast}
                            user={this.props.user}
                            forklifters={this.state.forklifters}
                            onUpdateCrate={this.getAllCrates}
                            crate={this.state.selectedCrate}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default DeploymentMasterDetailView;
