import React, {useState, useEffect} from "react";
import {Segment, Button, Container, Form, Grid, Select, Icon, Checkbox, Message} from "semantic-ui-react";
import ReactTable from "react-table-v6";
import DegradationForm from "./DegradationForm";
import TVEManagerDataProvider from "../../../Services/TVEManagerDataProvider";

const AUTH_TYPE_VIEW_OPTIONS = [
    {key: "getAuthN", text: "Get Authentication Status", value: "authenticated"},
    {key: "getAuthZ", text: "Get Authorization Status", value: "authorized"}
];

export default function DegradationDashboardMasterDetailView(props) {
    const [brandOptions, setBrandOptions] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState("");
    const [selectedAuthType, setSelectedAuthType] = useState("");
    const [degradationStatus, setDegradationStatus] = useState([]);
    const [isReenabling, setIsReenabling] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [apiError, setApiError] = useState("");

    TVEManagerDataProvider.init(process.env.TVE_MANAGER_URL);

    useEffect(function initBrands() {
        TVEManagerDataProvider.getBrands().then(response => {
            if (response.brands) {
                setBrandOptions(response.brands.map(brand => {
                    return {key: brand.id, text: brand.displayName, value: brand.id};
                }));
            } else {
                setBrandOptions([]);
            }
        });
    }, []);

    const triggerShowHistory = (event, data) => {
        console.log(data.checked);
        setShowHistory(data.checked)
    };

    const exludeHistory = (response) => {
        const removeFalseValues = (arr, valueToExclude, keyToCheck) => {
            const filtedArr = arr.map(subArray => 
                subArray["degradation-measure"].resource.filter(obj => obj[keyToCheck] !== valueToExclude)
            );
            return filtedArr;
        }

        const removedFalseValuesData = removeFalseValues(response.result, false, 'degradation-measure-enable')
        
        const createNewObject = (response, removedFalseValuesData) => {
            let newObj = {};
            newObj = response.result
            for (const key in newObj) {
                newObj[key]["degradation-measure"].resource = removedFalseValuesData[key]
            }
            return newObj;
        };

        const filteredFalseObject = createNewObject(response, removedFalseValuesData)
        const removedEmptyValues = filteredFalseObject.filter(measure => measure["degradation-measure"].resource.length > 0);

        console.log("excludeHistory: removed empty values: ", removedEmptyValues);
        return removedEmptyValues
    };
    
    const checkDegradationStatus = () => {
        setLoading(true);
        setApiError("");
        console.log("showHistory: ", showHistory)
        TVEManagerDataProvider.getDegradationStatus(selectedAuthType, selectedProperty).then(response => {
            if (showHistory === false) {
                const filteredArr = exludeHistory(response)
                console.log("filteredArr", filteredArr);
                setDegradationStatus(filteredArr.map(measure => {
                    const value = measure["degradation-measure"];
                    console.log(value);
                    return value;
                }));
            }else {
                setDegradationStatus(response.result.map(measure => {
                    const value = measure["degradation-measure"];
                    console.log(value);
                    return value;
                }));
            }
        }).catch(error => {
            console.log(error);
            setDegradationStatus([])
            if (error.response?.status_code ?? 500 >= 500) {
                console.log(error.response.data);
                setApiError(JSON.stringify(error.response.data, null, 4));
            }
            console.error(error);

        }).finally(() => {
            console.log(degradationStatus);
            setLoading(false);
        });
    };

    const deactivateDegradationClicked = (mvpd, programmer, resource) => {
        let authType = "";
        let property = selectedProperty;
        let resourceId = null;

        if (selectedAuthType === "authenticated") {
            authType = "authentication";
        } else if (selectedAuthType === "authorized") {
            authType = "authorization";
            property = programmer;
            resourceId = resource[0].id;
        }

        setIsReenabling(true);

        return TVEManagerDataProvider.deactivateDegradation(authType, property, mvpd, resourceId).then(response => {
            console.log(response);
            props.toast("success", `Deactivated ${authType} degradation for the MVPD ${mvpd} and the property ${property}`, "Success");
            checkDegradationStatus();
        }).catch(error => {
            props.toast("error", `Error deactivating ${authType} degradation for the MVPD ${mvpd} and the property ${property}`, "Error");
            console.error(error);
        }).finally(() => {
            setIsReenabling(false);
        });
    };

    return (
        <Grid className="masterDetailContainer">
            <Grid.Column width={11} className="masterContainer">
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field
                            control={Select}
                            label="Degradation Type"
                            value={selectedAuthType}
                            options={AUTH_TYPE_VIEW_OPTIONS}
                            onChange={(event, {value}) => setSelectedAuthType(value)}
                        />
                        <Form.Field
                            control={Select}
                            label="Property"
                            value={selectedProperty}
                            options={brandOptions}
                            onChange={(event, {value}) => setSelectedProperty(value)}
                        />
                        <Form.Field>
                            <label>&nbsp;</label>
                            <Checkbox
                                checked={showHistory}
                                onChange={triggerShowHistory}
                                label='Show History' 
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>&nbsp;</label>
                            <Button primary onClick={checkDegradationStatus}>Search</Button>
                        </Form.Field>
                    </Form.Group>
                    { apiError.length > 0 ?
                        <Message color="red" icon >
                            <Icon name="alert" />
                            <pre>{apiError}</pre>
                        </Message> : ""
                    }
                    <Container>
                        <ReactTable
                            data={degradationStatus}
                            loading={loading}
                            columns={[
                                {Header: "ID", accessor: "id"},
                                {Header: "MVPD", accessor: "mvpd"},
                                {accessor: "programmer", show: false},
                                {accessor: "resource", show: false},
                                {
                                    Header: "",
                                    Cell: row => loading ? "" :
                                        <Button
                                            primary
                                            fluid
                                            compact
                                            onClick={
                                                async () => {
                                                    console.log(row);
                                                    await deactivateDegradationClicked(row.original.mvpd, row.original.programmer, row.original.resource);
                                                }
                                            }
                                        >{isReenabling ? <><Icon name="spinner" loading/>Re-Enabling...</> : "Re-Enable"}</Button>
                                }
                            ]}
                            SubComponent={
                                row => <Segment><pre>{JSON.stringify(row.original.programmer, null, 4)}</pre><pre>{JSON.stringify(row.original.resource, null, 4)}</pre></Segment>
                            }
                            className="-striped -highlight"
                            defaultPageSize={18}
                            filterable
                            defaultFilterMethod={(filter, rows) => rows[filter.id].toString().toLowerCase().includes(filter.value.toString().toLowerCase())}
                        />
                    </Container>
                </Form>
            </Grid.Column>
            <Grid.Column width={5} className="detailsContainer">
                <DegradationForm
                    brandOptions={brandOptions}
                    TVEManagerDataProvider={TVEManagerDataProvider}
                    toast={props.toast}
                    service={props.service}
                    module={props.module}
                    user={props.user}
                    permissions={props.permissions}
                />

            </Grid.Column>
        </Grid>
    );
};
