import React, {useState, useEffect} from "react";
import {Form, Icon, Input, Message, Select} from "semantic-ui-react";
import ContingentButton from "../../ContingentButton";

const AUTH_TYPE_ENABLEMENT_OPTIONS = [
    {key: "enableAuthN", text: "Enable Authentication Degradation", value: "authentication"},
    {key: "enableAuthZ", text: "Enable Authorization Degradation", value: "authorization"}
];


export default function DegradationForm(props) {
    const [selectedAuthType, setSelectedAuthType] = useState("");
    const [selectedProperty, setSelectedProperty] = useState("");
    const [selectedResource, setSelectedResource] = useState("");
    const [providerOptions, setProviderOptions] = useState([]);
    const [selectedMVPD, setSelectedMVPD] = useState("");
    const [degradationMessage, setDegradationMessage] = useState("");
    const [degradationMessageType, setDegradationMessageType] = useState("");
    const [degradationSubmitted, setDegradationSubmitted] = useState(false);
    const [ttl, setTTL] = useState(300);

    useEffect(function updateMVPDOptions() {
        props.TVEManagerDataProvider.getProviders().then(response => {
            setProviderOptions(response.providers ? response.providers.map(provider => {
                return {key: provider.id, text: provider.displayName, value: provider.id};
            }) : []);
        });
    }, []);

    const degradeProvider = () => {
        setDegradationMessage("");
        setDegradationMessageType("");
        if (!selectedAuthType) {
            setDegradationMessageType("error");
            setDegradationMessage("Please select a degradation type.");
            return;
        } else if (selectedAuthType === "authorization" && !selectedResource) {
            setDegradationMessageType("error");
            setDegradationMessage("Please select a resource to degrade AuthZ.");
            return;
        }
        if (!selectedProperty) {
            setDegradationMessageType("error");
            setDegradationMessage("Please select a property to degrade.");
            return;
        }
        if (!selectedMVPD) {
            setDegradationMessageType("error");
            setDegradationMessage("Please select an MVPD to degrade.");
            return;
        }

        setDegradationSubmitted(true);
        props.TVEManagerDataProvider.activateDegradation(selectedAuthType, selectedProperty, selectedResource, selectedMVPD, ttl).then(response => {
            console.log("DegradationForm.degradeProvider: response: ", response);
            if (response.error) {
                setDegradationMessageType("error");
                setDegradationMessage("There was an error activating the degradation.");
                return;
            }
            setDegradationMessageType("success");
            setDegradationMessage(response.message);
        }).catch(error => {
            console.error(error);
            setDegradationMessageType("error");
            setDegradationMessage(error.toString());
        }).finally(() => {
            setDegradationSubmitted(false);
        });
    };

    return (
        <Form>
            <Form.Field>
                <label>&nbsp;</label>
                <ContingentButton
                    primary
                    onClick={degradeProvider}
                    iconPosition="left"
                    service={props.service}
                    module={props.module}
                    user={props.user}
                    allPermissions={props.permissions}
                    scope="any"
                >{degradationSubmitted ? <><Icon name="spinner" loading/>Degrading Provider</> : "Degrade Provider"}</ContingentButton>
            </Form.Field>
            {
                degradationMessage !== "" && degradationMessageType ?
                    <Message positive={degradationMessageType === "success"} negative={degradationMessageType === "error"}>
                        {degradationMessage}
                    </Message> : ""
            }
            <Form.Field
                control={Select}
                label="Degradation Type"
                value={selectedAuthType}
                options={AUTH_TYPE_ENABLEMENT_OPTIONS}
                onChange={(event, {value}) => setSelectedAuthType(value)}
            />
            <Form.Field
                control={Select}
                label="Property"
                value={selectedProperty}
                options={props.brandOptions}
                onChange={(event, {value}) => setSelectedProperty(value)}
            />
            <Form.Field
                control={Select}
                label="Resource (AuthZ only)"
                value={selectedResource}
                options={props.brandOptions}
                onChange={(event, {value}) => setSelectedResource(value)}
                disabled={selectedAuthType !== "authorization"}
            />
            <Form.Field
                control={Select}
                label="MVPD"
                value={selectedMVPD}
                options={providerOptions}
                onChange={(event, {value}) => setSelectedMVPD(value)}
            />
            <Form.Field
                control={Input}
                label="TTL"
                value={ttl}
                onChange={(event, {value}) => setTTL(value)}
                type="number"
            />
            </Form>
    );
};
