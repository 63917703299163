import React, {Component} from 'react';
import {Button, Container, Header, Grid, Icon, Message} from "semantic-ui-react";
import ReactTable from 'react-table-v6';
import ErrorReportForm from "./ErrorReportForm";
import FilterForm from "./ErrorReportFilterForm";
import Provider from "../../../Services/ErrorReportDataProvider";
import Modal from 'react-responsive-modal';

class ErrorReportMasterDetailView extends Component {
    isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: false,
            selectedKey: '',
            newClicked: false,
            clusterID: '',
            hourWritten: '',
            timestamp: '',
            webApp: '',
            logMessage: '',
            open: false
        };


        this.loadData = this.loadData.bind(this);
        this.loadFilteredData = this.loadFilteredData.bind(this);
        this.loadData();

    }

    changeClusterID = event => this.setState({clusterID: event.target.value});
    changeHourWritten = event => this.setState({hourWritten: event.target.value});
    changeTimestamp = event => this.setState({timestamp: event.target.value});
    changeWebApp = event => this.setState({webApp: event.target.value});
    changeLogMessage = event => this.setState({logMessage: event.target.value});

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    onClickRefresh = () => {
        this.setState( {
            loading: false,
            selectedKey: '',
            newClicked: false,
            clusterID: '',
            hourWritten: '',
            timestamp: '',
            webApp: '',
            logMessage: '',
            open: false
        });
        this.loadData();
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.authenticated !== nextProps.authenticated) {
            this.loadData();
        }
    }

    componentDidMount() {
        this.isMounted = true;
        Provider.init({baseURL: process.env.ERROR_REPORT_URL});
    }

    loadData() {
        this.props.checkIfAuthorized(["urn:guest:guest"]).then(authorized => {
            if (authorized) {
                this.setState({loading: true}, () => {
                    Provider.getAll().then(data => {
                        if (this.isMounted) {
                            if (data.error) {
                                this.setState({
                                    data: []
                                });
                                this.props.toast("Error Retrieving Data", data.error.message);
                            } else {
                                this.setState({
                                    data
                                });
                            }
                        }
                    }).catch(error => {
                        console.error(error);
                        this.props.toast("Error", error.message);
                        this.setState({data: null});
                    }).finally(() => this.setState({loading: false}));
                });
            } else {
                this.props.toast("Error", "403 Unauthorized");
            }
        });
    }

    loadFilteredData(id, hour, start, end, app, msg) {
        this.setState({
            data: [],
            loading: false,
            selectedKey: '',
            newClicked: false,
            clusterID: '',
            hourWritten: '',
            timestamp: '',
            webApp: '',
            logMessage: ''
        });

        this.props.checkIfAuthorized(["urn:guest:guest"]).then(authorized => {
            if (authorized) {
                this.setState({loading: true}, () => {
                    Provider.get(id, hour, start, end, app, msg).then(data => {
                        if (this.isMounted) {
                            if (data.error) {
                                this.setState({
                                    data: []
                                });
                                this.props.toast("Error Retrieving Data", data.error.message);
                            } else {
                                this.setState({
                                    data
                                });
                            }
                        }
                    }).catch(error => {
                        console.error(error);
                        this.props.toast("Error", error.message);
                        this.setState({data: null});
                    }).finally(() => this.setState({loading: false}));
                });
            } else {
                this.props.toast("Error", "403 Unauthorized");
            }
        });
    }

    render() {
        let that = this;
        const { open } = this.state;
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={11} className='masterContainer'>
                        <Grid>
                            <Grid.Column floated='right' width={16}>
                                <Container fluid textAlign='right' className='actionBarContainer'>
                                    <Button onClick={this.onClickRefresh} size='mini'><Icon name="refresh"/>Refresh</Button>
                                    <Button onClick={this.onOpenModal} size='mini'><Icon name="filter"/>Filter</Button>
                                </Container>

                            </Grid.Column>
                        </Grid>
                        <div>
                            <Modal open={open} onClose={this.onCloseModal} center>
                                <h2>Filter</h2>
                                <FilterForm
                                    selectedKey={this.state.selectedKey}
                                    clusterID={this.state.clusterID}
                                    hourWritten={this.state.hourWritten}
                                    timestamp={this.state.timestamp}
                                    timestampMonth={null}
                                    webApp={this.state.webApp}
                                    logMessage={this.state.logMessage}
                                    newClicked={this.state.newClicked}
                                    onClickSave={this.onClickSave}
                                    changeClusterID={this.changeClusterID}
                                    changeHourWritten={this.changeHourWritten}
                                    changeTimestamp={this.changeTimestamp}
                                    changeTimestampMonth={this.changeTimestampMonth}
                                    changeWebApp={this.changeWebApp}
                                    changeLogMessage={this.changeLogMessage}
                                    changeStartDate={this.changeStartDate}
                                    changeEndDate={this.changeEndDate}
                                    provider={Provider}
                                    loadFilteredData={this.loadFilteredData}
                                    onCloseModal={this.onCloseModal}
                                />
                            </Modal>
                        </div>
                        <ReactTable
                            columns={[
                                { accessor: 'uniqueID', show: false },
                                { Header: "Cluster ID", accessor: "clusterID" },
                                { Header: "Hour Written to BigQuery", accessor: "hourWritten" },
                                { Header: "Timestamp", accessor: "timestamp" },
                                { Header: "Web App", accessor: "webApp" },
                                { Header: "Log Message", accessor: "logMessage" }
                            ]}
                            data={this.state.data}
                            loading={this.state.loading}
                            filterable
                            defaultFilterMethod={(filter, rows) => rows[filter.id].toString().toLowerCase().includes(filter.value.toLowerCase())}
                            className="-striped -highlight"
                            getTrProps={(state, rowInfo, column, instance) => {
                                return {
                                    onClick(event, handleOriginal) {
                                        that.setState({selectedKey: rowInfo.row.uniqueID, newClicked: false,
                                        hourWritten: rowInfo.row.hourWritten, timestamp: rowInfo.row.timestamp,
                                        webApp: rowInfo.row.webApp, logMessage: rowInfo.row.logMessage,
                                        clusterID: rowInfo.row.clusterID, timestampHour: ""});
                                    },
                                    style: {
                                        background: rowInfo && rowInfo.row && that.state.selectedKey === rowInfo.row.uniqueID ? 'rgba(65, 83, 175, .5)' : '',
                                    }
                                }
                            }}
                            style={{height: '75vh'}}
                            defaultPageSize={100}
                        />
                    </Grid.Column>
                    <Grid.Column width={5} className='detailsContainer'>
                        <ErrorReportForm
                            selectedKey={this.state.selectedKey}
                            clusterID={this.state.clusterID}
                            hourWritten={this.state.hourWritten}
                            timestamp={this.state.timestamp}
                            webApp={this.state.webApp}
                            logMessage={this.state.logMessage}
                            changeClusterID={this.changeClusterID}
                            changeHourWritten={this.changeHourWritten}
                            changeTimestamp={this.changeTimestamp}
                            changeWebApp={this.changeWebApp}
                            changeLogMessage={this.changeLogMessage}
                            provider={Provider}
                            newClicked={this.state.newClicked}
                            onClickSave={this.onClickSave}
                            listToDatalist={this.props.listToDatalist}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export default ErrorReportMasterDetailView;
