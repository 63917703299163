import React, {Component} from 'react';
import {Form, Header} from "semantic-ui-react";

class ErrorReportForm extends Component {

    render() {

        //Adds spaces after commas and after substrings of a certain length that don't contain spaces
        //in order to prevent information in the sidebar from running off the screen
        let logMsg = this.props.logMessage;
        let newMsg = "";
        let lineLength = 40;
        if (logMsg !== undefined) {
            let spaceAdded = false;
            for (let i = 0; i < logMsg.length - 1; i++) {
                newMsg += logMsg.charAt(i);
                if (logMsg.charAt(i) === ',' && logMsg.charAt(i + 1) !== ' ') {
                    newMsg += " ";
                    spaceAdded = true;
                }

                if (i !== 0 && !spaceAdded && i % lineLength === 0) {
                    if (!logMsg.substring(i - lineLength, i).includes(" ")) {
                        newMsg += " ";
                    }
                } else if (i !== 0 && i % lineLength === 0) {
                    spaceAdded = false;
                }
            }
            newMsg += logMsg.charAt(logMsg.length - 1);
        }

        return (
            <Form>
                <Header>{"Details"}</Header>
                <br/>

                <Form.Field>
                    <label htmlFor="clusterID">Cluster ID</label>
                    <div>{this.props.clusterID}</div>
                </Form.Field>

                <Form.Field>
                    <label htmlFor="hourWritten">Hour Written to BigQuery</label>
                    <div>{this.props.hourWritten}</div>
                </Form.Field>

                <Form.Field>
                    <label htmlFor="timestamp">Timestamp</label>
                    <div>{this.props.timestamp}</div>
                </Form.Field>

                <Form.Field>
                    <label htmlFor="webApp">Web App</label>
                    <div>{this.props.webApp}</div>
                </Form.Field>

                <Form.Field>
                    <label htmlFor="logMessage">Log Message</label>
                    <div>{newMsg}</div>
                </Form.Field>

                <br/>
                {/*<Header>{"Analytics"}</Header>*/}
            </Form>
        );
    }
}

export default ErrorReportForm;